import React from 'react';
import { Snackbar, Alert, Typography, Slide } from '@mui/material';
import PetsIcon from '@mui/icons-material/Pets';

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

const CustomSnackbar = ({ open, message, type, onClose, duration = 2000 }) => {
    // Get icon color based on type (mixed with white)
   const iconColor = (theme) => {
      switch (type) {
        case 'success':
            return '#6aed6f';
        case 'error':
            return '#e91c41';
        default:
            return '#fff'
      }
   }
  
   return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={SlideTransition}
    >
      <Alert
        icon={<PetsIcon />}
        onClose={onClose}
        severity={type}
        sx={{
          width: '100%',
          backgroundColor: 'rgb(17 18 17 / 93%)',
          borderRadius: '13px',
          color: 'white',
          backdropFilter: 'blur(5px)',
          '& .MuiAlert-icon': {
            color: iconColor,
          },
        }}
      >
        <Typography fontFamily="sora, Arial, sans-serif" fontWeight="bold">
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;