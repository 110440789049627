// src/components/Home/LeaguesPopup.jsx
import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, LinearProgress, List, Grid,
 ListItem, ListItemIcon, ListItemText, Avatar, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/CloseRounded';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { userApi } from '../../services/api';
import { formatNumber } from '../../utils/formatters';
import backgroundImg from '../../assets/images/bg.png';

// Import Leagues Icon
import BronzeIcon from '../../assets/images/leagues/1.png';
import SilverIcon from '../../assets/images/leagues/2.png';
import GoldIcon from '../../assets/images/leagues/3.png';
import PlatinumIcon from '../../assets/images/leagues/4.png';
import DiamondIcon from '../../assets/images/leagues/5.png';
import RubyIcon from '../../assets/images/leagues/6.png';
import EmeraldIcon from '../../assets/images/leagues/7.png';
import SapphireIcon from '../../assets/images/leagues/8.png';
import PalladiumIcon from '../../assets/images/leagues/9.png';
import RhodiumIcon from '../../assets/images/leagues/10.png';

const PopupBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  maxWidth: '100%',
  maxHeight: '85vh',
  overflowY: 'auto',
  background: 'linear-gradient(358deg, black, #4b00a6bd)',
  backdropFilter: 'blur(5px)',
  // background: `url(${backgroundImg}) repeat-x`,
  // backgroundSize: 'cover',
  padding: theme.spacing(3),
  color: 'white',
  borderTopLeftRadius: theme.spacing(7),
  borderTopRightRadius: theme.spacing(7),
  borderTop: '5px solid orange',
  boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.5s ease-in-out',
  transform: 'translateY(100%)',
  zIndex: 999,
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  borderRadius: theme.spacing(3.5),
  marginBottom: theme.spacing(1),
  paddingLeft: 0,
}));

const LeaguesPopup = ({ userId, open, onClose, points, nextLeaguePointsRequired, userLeagueIndex, telApp }) => {
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLeagues = async () => {
      if (!open) return;

      setLoading(true);
      try {
        const response = await userApi.getLeagues();
        const mappedLeagues = Object.entries(response.data).map(([key, value]) => ({
          id: key,
          ...value
        }));
        setLeagues(mappedLeagues);
      } catch (error) {
        console.error("Error fetching leagues:", error);
        telApp.showAlert("Failed to fetch leagues data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchLeagues();
  }, [open, telApp]);

  const leagueIndexIcon = (index) => {
    const leaguesIcons = [BronzeIcon, SilverIcon, GoldIcon, PlatinumIcon, DiamondIcon, RubyIcon, EmeraldIcon, SapphireIcon, PalladiumIcon, RhodiumIcon];
    return leaguesIcons[index - 1];
  };

  const shortenNumber = (number) => {
    if (number >= 100000) {
      return (number / 1000).toFixed(1) + 'K';
    } else if (number >= 10000) {
      return (number / 1000).toFixed(1) + 'K';
    } else {
      return formatNumber(number);
    }
  };

  return (
    <PopupBox sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)' }}>
      <IconButton
        sx={{ position: 'absolute', top: 16, right: 16, color: 'white' }}
        onClick={onClose}
      >
        <CloseIcon fontSize="large" />
      </IconButton>

      <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
        <Avatar
          alt="League"
          src={leagueIndexIcon(userLeagueIndex)}
          sx={{ width: 170, height: 170, mb: 1 }}
        />
        <Typography variant="h5" component="h1" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" textAlign="center" mb={2}>
          <span style={{backgroundColor: 'rgba(255, 255, 255, 0.2)', padding: '2px 6px 2px 9px', borderRadius: '15px' }}> lvl {userLeagueIndex} </span>
          &nbsp; {leagues[userLeagueIndex - 1]?.id || ''}
        </Typography>
        <Grid display="flex" flexDirection="row" justifyContent="space-between" width="100%">
          <Typography variant="body2" fontFamily="sora, sans-serif" fontWeight="bold">
            Next lvl: {userLeagueIndex + 1}
          </Typography>
          <Typography variant="body2" fontFamily="sora, sans-serif" fontWeight="bold">
            {formatNumber(points)} / {formatNumber(nextLeaguePointsRequired + points)}    
          </Typography>
        </Grid>
        <Box width="100%" mb={1}>
          <LinearProgress
            variant="determinate"
            value={Math.round((points / (nextLeaguePointsRequired + points)) * 100)}
            sx={{
              height: 8,
              borderRadius: 4,
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#4CAF50',
              },
            }}
          />
        </Box>
      </Box>

      <List sx={{ width: '95vw', position: 'relative', right: '11px' }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
            <CircularProgress />
          </Box>
        ) : leagues.length > 0 ? (
          leagues.map((league, index) => (
            <StyledListItem key={index}>
              <ListItemIcon>
                <Avatar
                  alt={league.id}
                  src={leagueIndexIcon(index + 1)}
                  sx={{ width: 55, height: 55, ml: 1 }}
                >
                  {index + 1}
                </Avatar>
              </ListItemIcon>
              <ListItemText
                primary={league.id}
                secondary={
                  <React.Fragment>
                    <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', fontFamily: 'sora, sans-serif', fontWeight: 'bold', color: 'gold', mr: 0.5 }}>
                      <MonetizationOnIcon fontSize="small" sx={{ mr: 0.5 }} />
                      {shortenNumber(league.pointsRequired)}
                    </Box>
                    <Typography component="span" variant="body1" fontFamily="sora, sans-serif" color="white" sx={{ ml: 7, position: 'relative', bottom: '3px' }}>
                      Budget: {shortenNumber(league.allowance)}
                    </Typography>
                  </React.Fragment>
                }
                primaryTypographyProps={{ fontWeight: 'bold' }}
                secondaryTypographyProps={{ component: 'div', display: 'flex', alignItems: 'flex-start', mt: 0.75 }}
              />
              <Typography variant="h4" fontFamily="sora, sans-serif" fontWeight="bold">
                {index + 1}
              </Typography>
            </StyledListItem>
          ))
        ) : (
          <Typography variant="body1" textAlign="center">No leagues data available</Typography>
        )}
      </List>
    </PopupBox>
  );
};

export default LeaguesPopup;