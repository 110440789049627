import React from 'react';
import { Box, Typography, Button} from '@mui/material';
import candy from '../../assets/images/gifs/candy.gif';
import { styled } from '@mui/system';

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '96%',
  height: '45vh',
//   makeit to linear gradient
  background: 'linear-gradient(358deg, black, #4b00a6bd)',
  backdropFilter: 'blur(10px)',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '25px',
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  marginLeft: 'auto',
  marginRight: 'auto',
//   change color of everything to white
  '*': {
    color: 'white',
  },
  zIndex: 999,
}));
 
const PopupImage = styled('img')({
  width: '125px',
  height: '125px',
  position: 'relative',
  bottom: '5px',
  left: '65px',
  transform: 'translateX(50%)',
});


function RewardsPopup(props) {
   const { open, onClose, setOpenConfetti } = props;

   const handleClaimRewards = async () => {
    onClose();
    setOpenConfetti(true);

    setTimeout(() => {
      setOpenConfetti(false);
    }, 5000);
   }

  return (
    // when touched outside popup will close
    <PopupContainer sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)', }} onClose={onClose}>
      {/* Close button */}
      <Box display="flex" flexDirection="column" justifyContent="center" position='relative' top='15px' >
        <PopupImage src={'https://raw.githubusercontent.com/Tarikul-Islam-Anik/Telegram-Animated-Emojis/main/Food%20and%20Drink/Birthday%20Cake.webp'} alt="Wonka" loading='lazy' />
        <Typography fontFamily="Sora, sans-serif" fontWeight="bold" fontSize="18px" textAlign="center" sx={{ color: 'white', px: 2, mb: 2 }}>
             Nice to see you back, Please claim your farming rewards! 
        </Typography>
      </Box>

        {/* Send Tip */}
      <Button 
            variant="contained"
            color="primary"
            onClick={handleClaimRewards}
            sx={{
              position: 'absolute', 
              left: '48vw',
              bottom: '2.5vh',
              transform: 'translate(-50%, 0%)',
              width: '90vw',
              height: '9vh',
              borderRadius: '20px',
              textTransform: 'none',
              fontFamily: 'Sora',
              fontSize: '18px',
              '&:hover': {
                border: '3px solid white',
              }
            }}
           >
             Claim 150 Points
        </Button>
    </PopupContainer>
  );
};

export default RewardsPopup;