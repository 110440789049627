import React, { useEffect, useState } from 'react';
import WonkaHome from './components/Home';
import TaskPage from './components/Task';
import RewardsPage from './components/Rewards';
import ReferralPage from './components/Referral';
import MaintenancePage from './components/Maintenance';
import LoadingScreen from './components/Home/LoadingScreen';
import CustomSnackbar from './components/Snakebar/Snakebar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from "react-router-dom";
import { userApi } from './services/api'

const telApp = window.Telegram.WebApp;

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1280,
    },
  },
});

const ENDPOINT = process.env.REACT_APP_API_URL;

function App() {
  const [isTelegramMiniApp, setIsTelegramMiniApp] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userProfileInfo, setUserProfileInfo] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);
  const [userPlatform, setUserPlatform] = useState(null);
  const [openConfetti, setOpenConfetti] = useState(false);
  const [startAppId, setStartAppId] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info'
  });

  const init = async () => {
    telApp.ready();    
    telApp.expand();
    telApp.setHeaderColor('#010510');

    setUserPlatform(telApp.platform);
    setStartAppId(Number(telApp.initDataUnsafe.start_param));

    const initData = telApp.initData;
    
    if (initData) {
      try {
        const response = await userApi.authenticateUser(initData, startAppId);
        if (response.data.authenticated) {
          localStorage.setItem('jwtToken', response.data.token);
          setUserData(response.data.user);
          setIsTelegramMiniApp(true);
          await getProfileInfo(response.data.user.id);
        } else {
          throw new Error('Authentication failed');
        }
      } catch (error) {
        console.error('Authentication error:', error);
        telApp.showAlert('Authentication failed. Please try again.');
        telApp.close();
      }
    } else {
      setIsTelegramMiniApp(false);
      // For development purposes, you can set a mock user here
      setUserData({"id": 1887509957,"first_name": "Its Justin","last_name": "","username": "P2P_JS","language_code": "en","is_premium": true,"allows_write_to_pm": true});
      await getProfileInfo(1887509957);
    }
  }

  const getProfileInfo = async (userId) => {
    try {
      const response = await userApi.getUserData(userId);
      setUserProfileInfo(response.data);
      setProfileUrl(`https://app.foxi.tips/api/public/users/avatars/${userId}.jpg`);
    } catch (error) {
      console.error('Error fetching user data:', error);
      telApp?.showAlert('Failed to get user information data!\nFor support, Please check bot profile bio!');
      telApp.close();
    }
  }

  const showSnackbar = (message, type) => {
    setSnackbar({ open: true, message, type });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    init();
  }, []);

  if (!userProfileInfo) {
    return <LoadingScreen />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        onClose={closeSnackbar}
      />

      <Routes>         
        <Route path="/" element={
          <WonkaHome 
            theme={theme}
            userData={userData}
            userProfileInfo={userProfileInfo} 
            setUserProfileInfo={setUserProfileInfo}
            profileUrl={profileUrl}
            userPlatform={userPlatform} 
            openConfetti={openConfetti}
            setOpenConfetti={setOpenConfetti}
            showSnackbar={showSnackbar}
            telApp={telApp}
            ENDPOINT={ENDPOINT}
          />
        } />
         
        <Route path="/task" element={
          <TaskPage 
            theme={theme}
            userData={userData} 
            openConfetti={openConfetti}
            setOpenConfetti={setOpenConfetti}
            telApp={telApp}
            ENDPOINT={ENDPOINT}
          />
        } />

        <Route path="/rewards" element={
          <RewardsPage
            theme={theme}
            userProfileInfo={userProfileInfo} 
            setUserProfileInfo={setUserProfileInfo}
            openConfetti={openConfetti}
            setOpenConfetti={setOpenConfetti}
            showSnackbar={showSnackbar}
            telApp={telApp}
            ENDPOINT={ENDPOINT}
          />
        } />

        <Route path="/referrals" element={
          <ReferralPage
            theme={theme}
            userData={userData}
            userProfileInfo={userProfileInfo}
            telApp={telApp}
            ENDPOINT={ENDPOINT}
          />
        } />

        <Route path="/maintenance" element={<MaintenancePage />} />
   
        <Route path="/waitlist" element={
          <WonkaHome 
            theme={theme}
            userData={userData}
            profileUrl={profileUrl}
            userPlatform={userPlatform} 
            openConfetti={openConfetti}
            setOpenConfetti={setOpenConfetti}
            telApp={telApp}
            ENDPOINT={ENDPOINT}
          />
        } />
      </Routes>
    </ThemeProvider>
  );
}

export default App;