import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';

// Define the images for the coin and diamond
const coinIconSrc = 'https://img.icons8.com/fluency/50/cheap-2.png';
const diamondIconSrc = 'https://img.icons8.com/color/48/diamond.png';

const StickyInfoBoxes = (props) => {
  const { userProfileInfo, isSpinning } = props;

  // for each 3 digits
  const formatNumber = (number) => {
    return Number(number).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100vw',
        left: '0px',
        height: '16vh',
        overflow: 'hidden',
        bgcolor: '#ff9926', // Background color for the whole page
      }}
    >
      {/* Left Sticky Box for Coin */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: isSpinning ? '-30%' : 0,
          transform: 'translateY(-50%)', // Vertically center the box
          width: '150px',
          height: '100px',
          backdropFilter: 'blur(3px)',
          bgcolor: 'rgba(13, 30, 53, 0.7)',
          borderRadius: '0px 50px 50px 0px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          transition: 'all 0.5s ease',
          zIndex: 1000,
        }}
      >
        <img src={coinIconSrc} alt="Coin Icon" style={{ width: '40px', height: '40px' }} />
        <Typography
          fontSize={'16px'}
          fontFamily={'sora, sans-serif'}
          fontWeight={'bold'}
          sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center', marginTop: '8px' }}
        >
           {formatNumber(userProfileInfo?.points)}
        </Typography>
      </Box>

      {/* Right Sticky Box for Diamond */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: isSpinning ? '-30%' : 0,
          transform: 'translateY(-50%)', // Vertically center the box
          width: '150px',
          height: '100px',
          backdropFilter: 'blur(3px)',
          bgcolor: 'rgba(13, 30, 53, 0.7)',
          borderRadius: '50px 0px 0px 50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          transition: 'all 0.5s ease',
          zIndex: 1000,
        }}
      >
        <img src={diamondIconSrc} alt="Diamond Icon" style={{ width: '40px', height: '40px' }} />
        <Typography
          fontSize={'16px'}
          fontFamily={'sora, sans-serif'}
          fontWeight={'bold'}
          sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center', marginTop: '8px' }}
        >
           {userProfileInfo?.diamonds}
        </Typography>
      </Box>
    </Box>
  );
};

export default StickyInfoBoxes;