import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import ListAltIcon from '@mui/icons-material/ListAlt';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import GroupIcon from '@mui/icons-material/Group';
import PetsIcon from '@mui/icons-material/Pets';
import FoxiCharacter from '../../assets/images/home-elements/foxi-character.png';
import { Link } from 'react-router-dom';

const TabsContainer = styled(Box)(({ theme, blurEffect = false }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#02071cc7',
  backdropFilter: blurEffect ? 'blur(5px)' : 'none',
  borderRadius: theme.spacing(5, 5, 0, 0),
  padding: theme.spacing(1, 3),
  width: '85vw',
  height: '50px', // Set a fixed height
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'fixed', // Position it at the bottom of the screen
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 99, // Ensure it's above other elements
}));

const NavButton = styled(Button)(({ theme, active }) => ({
  flex: 1,
  borderRadius: 0,
  color: 'white',
  textTransform: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  transition: 'all 0.4s ease',
  // For the active tab
  ...(active && {
    flex: 1,
    padding: theme.spacing(0),
    backgroundColor: '#483d8b94', // Custom background for active
    borderRadius: theme.spacing(2), // Border radius for active
    // border: '5px solid #ccdcff96',
    transform: 'translateY(-25px)', // Moves it slightly upwards
    backdropFilter: 'blur(8px)',
    fontWeight: 700,
    fontFamily: 'sora, sans-serif',
    // run goToTop animation only 1 time
    animation: `${navButtonAnimation} 0.25s ease-in-out 1`,
    width: '75px',
    height: '70px',
    zIndex: 1, // Ensure it’s on top
  }),

  '& .MuiSvgIcon-root': {
    fontSize: active ? 28 : 24,
    marginBottom: active ? theme.spacing(0.5) : 0,
    transition: 'all 0.4s ease',
  },
  '& .MuiTypography-root': {
    fontWeight: active ? 800 : 400,
    opacity: active ? 1 : 0,
    height: active ? 'auto' : 0,
    overflow: 'hidden',
    transition: 'all 0.4s ease',
  },

  '&:hover': {
    transform: 'scale(1.1)',
    borderRadius: '10px',
  },
}));

// scale and go to top animation
const navButtonAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7px);
  }
  100% {
    transform: translateY(-17px);
  }`;

const tabs = [
  { 
     icon: <PetsIcon />, 
     label: 'Home',
     link: '/'},
  { icon: <ListAltIcon />, 
    label: 'Task', 
    link: '/task' },
  { 
    icon: <EmojiEventsIcon />, 
    label: 'Rewards',
    link: '/rewards' },
  { 
    icon: <GroupIcon />, 
    label: 'Referral',
    link: '/referrals' },
];

export default function BottomTabs({ activeTab, blurEffect = false}) {
  return (
    <TabsContainer blurEffect={blurEffect}>
      {tabs.map((tab, index) => (
        <Link to={tab.link} key={tab.label}>
          <NavButton key={tab.label} active={index === activeTab}>
            {tab.icon}
            <Typography variant="caption">{tab.label}</Typography>
          </NavButton>
        </Link>
      ))}
    </TabsContainer>
  );
}