import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, IconButton, TextField, Avatar, 
  CircularProgress, ListItemText, Typography, Grid,
  ListItem, List } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import SendIcon from '@mui/icons-material/Send';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { userApi, tipApi } from '../../services/api';
import { formatNumber } from '../../utils/formatters';
import FoxiSearch from '../../assets/images/characters/foxi-search.png';
import FoxiCharacter from '../../assets/images/characters/foxi-half-body.png';
import BackgroundImg from '../../assets/images/bg.png';

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '90vh',
  background: 'linear-gradient(358deg, black, #4b00a6bd)',
  // background: `url(${BackgroundImg}) repeat-x`,
  // backgroundSize: 'cover',
  backdropFilter: 'blur(5px)',
  borderTop: '5px solid orange',
  borderTopLeftRadius: theme.spacing(7),
  borderTopRightRadius: theme.spacing(7), 
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: 'white',
  zIndex: 999,
}));

const PopupImage = styled('img')({
  width: '140px',
  height: '140px',
  position: 'absolute',
  top: 'calc(-8vh - 1px)',
  left: '50%',
  transform: 'translateX(-50%)',
});

const SearchingImage = styled('img')({
  position: 'absolute',
  left: '40%',
  top: '40vh',
  width: '100px',
  height: '105px',
  transform: 'translateX(-50%)',
  filter: 'brightness(0) invert(1)',
});

const StyledList = styled(List)(({ theme }) => ({
  height: '55vh',
  overflow: 'auto',
  width: '100%',
  padding: 0,
}));

const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
  width: '100%',
  height: selected ? '65px' : '60px',
  borderRadius: '15px',
  backgroundColor: 'rgb(0 10 19 / 50%)',
  margin: '8px auto',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '30px',
    height: '55px',
    border: '3px solid white',
    backgroundColor: '#8b50e1',
  },
  '& .MuiOutlinedInput-input': {
    color: 'white',
    fontFamily: 'Sora',
  },
}));

const StyledButton = styled(Button)(({ theme, isCancellButton }) => ({
  position: 'absolute',
  left: '48vw',
  transform: 'translateX(-50%)',
  width: '90%',
  height: '8vh',
  textTransform: 'none',
  fontFamily: 'Sora',
  fontSize: '18px',
  borderRadius: '10rem',
  color: 'white',
  margin: theme.spacing(0, 1),
  padding: '12px 16px',
  backgroundColor: isCancellButton ? '#ec2929' : '#5529ec',
  backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent),radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
  boxShadow: isCancellButton ? `inset 0 -2px 4px 1px rgb(236 41 41 / 71%), inset 0 -4px 4px 1px #ffffff, inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(56, 41, 236, 0.2), 0 1px 3px 1px #ee2283eb` : 
  `inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6), inset 0 -4px 4px 1px #c5b3ff, inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(56, 41, 236, 0.2), 0 1px 3px 1px #5529ec`,
  textShadow: '0 1px 1px #3829ec',
  cursor: 'pointer',
  transition: 'all 0.2s',
  '&:hover': {
    width: '85%',
  },
  '&:active': {
    borderColor: '#2e23d6',
    width: '85%',
    backgroundColor: '#2e23d6',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50px',
    width: '80%',
    height: '40%',
    backgroundImage: 'linear-gradient(to bottom, #e9f0ff, transparent)',
    opacity: 0.75,
  },
  '&:focus': {
    outline: 'none',
  }
}));

// opacity animation
const fadeIn = keyframes`
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 0.75;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
  
const SendTipPopup = ({ userId, remainingAllowance, open, onClose, showSnackbar, telApp }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [tipAmount, setTipAmount] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [searching, setSearching] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [tipLoading, setTipLoading] = useState(false);
  const [tippedUsers, setTippedUsers] = useState([]);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const handleSearch = useCallback(
    debounce(async (query) => {
      if (query && query !== '') {
        try {
          setSearching(true);
          const response = await userApi.searchUsers(query);
          setSearchResults(response.data);
        } catch (error) {
          console.error('Error searching users:', error);
          showSnackbar('Failed to search users. Please try again.');
        } finally {
          setSearching(false);
        }
      } else {
        selectUser(null);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (searchQuery && searchQuery !== '') {
      handleSearch(searchQuery);
    } else {
      setSearching(false);
    }
  }, [searchQuery, handleSearch]);

  const handleSendTip = async () => {
    const targetUserId = userSelected ? userSelected.userId : null;

    if (tipLoading) {
      showSnackbar('Please wait while we process your tip...', 'info');
      return;
    }
    if (!targetUserId) {
      showSnackbar('Please select a user', 'info');
      return;
    }
    if (tipAmount <= 0) {
      showSnackbar('Enter an amount greater than 0', 'info');
      return;
    }
    if (tipAmount > remainingAllowance) {
      showSnackbar('Insufficient allowance', 'error');
      return;
    }

    setTipLoading(true);
    try {
      await tipApi.tipUserWebapp({ userId, targetUserId, amount: Number(tipAmount || 0) });
      setTippedUsers([...tippedUsers, targetUserId]);
      showSnackbar('tip sent successfully!', 'success');
    } catch (error) {
      showSnackbar('Error, try again later!', 'error');
    } finally {
      setTimeout(() => {
        setTipLoading(false);
        selectUser(null);
        setSearchQuery('');
      }, 500);
    }
  };

  const selectUser = (user) => {
    setTipLoading(false);
    setSearchQuery(user ? user.username : '');
    setUserSelected(user);
  };

  return (
    <PopupContainer sx={{ transform: open ? 'translateY(0)' : 'translateY(110%)' }}>
      <IconButton onClick={onClose} sx={{ position: 'absolute', right: 16, top: 16 }}>
        <CloseIcon fontSize="large" fontWeight="bold" htmlColor="white" />
      </IconButton>

      <PopupImage src={FoxiCharacter} alt="Wonka" loading='lazy' />
      <SearchingImage 
        src={FoxiSearch} 
        alt="Search" 
        loading="lazy" 
        sx={{ opacity: searching ? 1 : 0, transition: 'opacity 0.6s ease-in-out', 
          animation: searching && `${fadeIn} 1s ease-in-out infinite`, zIndex: 99 }} 
      />
      
      {/* Popup content */}
      <Box display="flex" flexDirection="column" alignItems="center" p={2} mt={8}>
        <StyledTextField
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          variant="outlined"
          placeholder="Search by Name or Username"
          type='text'
          fullWidth
          sx={{ mb: 2 }}
        />
        
        {userSelected && (
          <Box display="flex" justifyContent="space-between" width="100%" mb={2}>
            <StyledTextField
              value={tipAmount}
              onChange={(e) => setTipAmount(e.target.value)}
              variant="outlined"
              placeholder="Tip Amount"
              type='number'
              sx={{ width: '48%' }}
            />
            <Grid container direction="column" justifyContent="center" alignItems="center" width="48%" border='3px solid white' borderRadius='30px'>
              <Typography variant="h6" fontSize="15px" fontFamily="sora, sans-serif" fontWeight="500" sx={{ color: 'gold', position: 'relative', top: '5px' }}>
                Budget Left
              </Typography>
              <Typography variant="h6" fontSize="17px" fontFamily="sora, sans-serif" fontWeight="bold" sx={{ color: 'gold' }}>
                {formatNumber(remainingAllowance)}
              </Typography>
            </Grid>
          </Box>
        )}

        <StyledList>
          {searchResults && !userSelected
            ? searchResults.slice(0, 10).map((user) => (
                <StyledListItem onClick={() => selectUser(user)} key={user.userId}>
                  <Avatar
                    alt={user.username}
                    src={`${process.env.REACT_APP_API_URL}/public/users/avatars/${user.userId}.jpg`}
                    sx={{ width: 45, height: 45, mr: 2.5 }}
                  />
                  <Box>
                    <ListItemText 
                      primary={user.username} 
                      secondary={user.firstname}
                      primaryTypographyProps={{ fontFamily: 'sora', fontWeight: 'bold', fontSize: '18px' }}
                      secondaryTypographyProps={{ fontFamily: 'sora', fontWeight: '300', fontSize: '15px', color: 'white' }}
                    />
                  </Box>
                  <RadioButtonUncheckedIcon sx={{ position: 'absolute', right: 16 }} />
                </StyledListItem>
              ))
            : userSelected && (
                <StyledListItem selected>
                  <Avatar
                    alt={userSelected.username}
                    src={`${process.env.REACT_APP_API_URL}/public/users/avatars/${userSelected.userId}.jpg`}
                    sx={{ width: 55, height: 55, mr: 2.5 }}
                  />
                  <Box>
                    <ListItemText 
                      primary={userSelected.username} 
                      secondary={userSelected.firstname}
                      primaryTypographyProps={{ fontFamily: 'sora', fontWeight: 'bold', fontSize: '18px' }}
                      secondaryTypographyProps={{ fontFamily: 'sora', fontWeight: '300', fontSize: '15px', color: 'white' }}
                    />
                  </Box>
                  <RadioButtonCheckedIcon sx={{ position: 'absolute', right: 16 }} />
                </StyledListItem>
              )
          }
        </StyledList>

        {userSelected && (
          <StyledButton
            isCancellButton={true}
            variant="contained"
            color="error"
            onClick={() => {
              setUserSelected(null);
              setSearchQuery('');
            }}
            sx={{ bottom: '12vh' }}
          >
            Cancel
          </StyledButton>
        )}

        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleSendTip}
          sx={{ 
            bottom: userSelected ? '2vh' : '2.5vh',
            opacity: userSelected ? 1 : 0.5,
          }}
        >
          {tipLoading ? (
            <span>
              Sending... 
              <CircularProgress size={20} thickness={5} disableShrink sx={{ color: 'white', ml: 1, verticalAlign: 'middle' }} />
            </span>
          ) : (
            <span>
              Send Tip 
              <SendIcon sx={{ fontSize: '22px', ml: 1, verticalAlign: 'middle' }} />
            </span>
          )}
        </StyledButton>
      </Box>
    </PopupContainer>
  );
};

export default SendTipPopup;