import React from 'react'
import { 
     List, Grid, ListItem, ListItemIcon, ListItemText, 
     Button, CircularProgress } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import XIcon from '../../assets/images/icons/x.png';
import YoutubeIcon from '../../assets/images/icons/youtube.png';
import FarcasterIcon from '../../assets/images/icons/farcaster.png';
import TelegramIcon from '../../assets/images/icons/telegram.png';

const StyledTaskButton = styled(Button)(({ theme, active = false }) => ({
  borderRadius: '10rem',
  color: 'white !important',
  textTransform: 'none',
  height: '6.5vh',
  width: '26%',
  padding: active ? '12px 18px' : '12px 16px',
  backgroundColor: active ? 'rgb(255 117 4)' : '#5529ec', // Change to orange-yellow if active
  backgroundImage: active 
    ? 'radial-gradient(75% 50% at 50% 0%, rgba(255, 165, 0, 0.8), transparent), radial-gradient(75% 35% at 50% 80%, rgba(255, 255, 255, 0.54), transparent)' 
    : 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent), radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
  boxShadow: active 
    ? '0 4px 10px rgba(255, 165, 0, 0.5), 0 2px 5px rgba(255, 140, 0, 0.5)' // Shadow for active state
    : `inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6), inset 0 -4px 4px 1px #c5b3ff, inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(56, 41, 236, 0.2), 0 1px 3px 1px #5529ec`,
  textShadow: active ? '0 1px 1px rgba(255, 165, 0, 0.7)' : '0 1px 1px #3829ec',
  transition: 'border-color 0.2s, transform 0.2s, background-color 0.2s',
  cursor: 'pointer',
  fontSize: active ? '14px' : '13px',
  fontWeight: 'bold',
  fontFamily: 'sora, sans-serif',
  '&:hover': {
    transform: 'scale(1.04)',
    backgroundColor: 'rgb(255 148 4)',
  },
  '&:active': {
    borderColor: active ? '#d68e00' : '#2e23d6', // Change border color if active
    transform: 'scale(0.96)',
  },
  '&:focus': {
    outline: 'none',
  },
}));

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const iconsList = (type) => {
    const icons = {
        'Telegram': <img width="60px" height="60px" src={TelegramIcon} alt="Telegram" />,
        'X': <img width="60px" height="60px" src={XIcon} alt="X" />,
        'Youtube': <img width="57px" height="57px" src={YoutubeIcon} alt="Youtube" />,
        'Farcaster': <img width="55px" height="55px" src={FarcasterIcon} alt="Farcaster" />
    }
    return icons[type];
}

const TaskList = ({ tasks, taskType = "basic", userId, telApp, setActiveTask, setOpenConfirmPopup }) => {
   const handleTaskClick = (index) => {
      if (tasks[index - 1].usersCompleted.includes(String(userId))) {
        telApp?.showAlert('You have already completed this task!');
      } else {
        setOpenConfirmPopup(true);
        setActiveTask(index);
      }
   }

   const taskStyle = (task, index, userId) => {
    const completed = task.usersCompleted.includes(userId);
    const hasDiamondRewards = task.diamonds > 0;
    const coinIconSrc = 'https://img.icons8.com/fluency/50/cheap-2.png';
    const diamondIconSrc = 'https://img.icons8.com/color/48/diamond.png';

    return (
       <ListItem key={index} onClick={() => handleTaskClick(index + 1)} divider 
          sx={{ 
             background: '#00000086', 
             backdropFilter: 'blur(4px)', 
             borderRadius: '40px', 
             mb: 1, ml: 1, py: 0,
             width: '94vw', 
             height: '10vh',
             maxHeight: '15vh',
             pr: 1, pl: 1.5,
             animation: `${fadeIn} 0.5s ease-in-out`,
             animationDelay: `${index * 0.075}s`,
             '&:hover': { backgroundColor: 'rgba(255, 25, 50, 0.8)' } 
            }}>
          <ListItemIcon sx={{ mr: 1 }}>
            {iconsList(task.category)}
          </ListItemIcon>

          <ListItemText 
              primary={task.title} 
              secondary={
                hasDiamondRewards 
                  ? (
                      <>
                        <img src={diamondIconSrc} alt="Diamond" style={{ width: '18px', marginRight: '2px', verticalAlign: 'middle' }} />
                        {`${task.diamonds}`}
                        &nbsp;&nbsp;
                        <img src={coinIconSrc} alt="Points" style={{ width: '16px', marginRight: '2px', verticalAlign: 'middle' }} />
                        {`${task.points}`}
                      </>
                    )
                  : (
                      <>
                        <img src={coinIconSrc} alt="Points" style={{ width: '17px', marginRight: '2px', verticalAlign: 'middle' }} />
                        {`${task.points}`} 
                      </>
                    )
              }
              primaryTypographyProps={{ fontSize: '14.5px', fontFamily: 'Sora, sans-serif', fontWeight: 'bold', color: 'white', lineHeight: '18px' }} 
              secondaryTypographyProps={{ fontFamily: 'Poppins-Medium, sans-serif', color: 'white' }}
              sx={{ width: '85%' }} />

          {completed ? (
            <StyledTaskButton disabled>
              Done 
            </StyledTaskButton>
          ) : (
            <StyledTaskButton active={true}>
               Start 
            </StyledTaskButton>
          )}
      </ListItem>
    )
   }

  return (
    <Grid container spacing={2} pt={2} justify="center" height={'60vh'} sx={{ overflowY: 'scroll', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'space-between', justifyContent: 'space-around', alignItems: 'center'   }}>
       <List sx={{ alignItems: 'center', alignContent: 'center' }}>
         {tasks?
           tasks.map((task, index) => (
            task.type === taskType && taskStyle(task, index, userId)
          )) :
          <CircularProgress size={70} thickness={7} sx={{ color: '#BEB7FF', ml: 4, mt: 3 }} />
        }
       </List>
    </Grid>
    );
};
  
export default TaskList;