import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button, LinearProgress, Avatar, Paper, Grid } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { userApi, miscApi } from '../services/api';
import { calculateRemainingTime_HMS } from '../utils/Time';
import { formatNumber } from '../utils/formatters';

import FoxiCharacter from '../assets/images/home-elements/foxi-character.png';
import DiamondPng from '../assets/images/home-elements/diamond.png';
import CheckInPng from '../assets/images/home-elements/check-in.png';
import ReceiveTipIcon from '../assets/images/home-elements/receive-tip.png';
import SendTipIcon from '../assets/images/home-elements/send-tip.png';
import BG2 from '../assets/images/home-elements/bg-2.png';
import BG3 from '../assets/images/home-elements/bg-3.jpg';
import NeonCircle from '../assets/images/home-elements/yellow-neon-2.png';
import PetsIcon from '@mui/icons-material/Pets';

import SendTipPopup from './Home/SendTipPopup';
import HistoryPopup from './Home/HistoryPopup';
import LeaguesPopup from './Home/LeaguesPopup';
import RewardsPopup from './Home/RewardsPopup';
import NewLeaguePopup from './Home/NewLeaguePopup';
import BottomTabs from './Tabs/Tabs';
import Confetti from './Confetti/Confetti';

const StyledPaper = styled(Paper)(({ theme }) => ({
  background: `url(${BG3})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: 'white',
  padding: 0,
  borderRadius: 0,
  minHeight: '100vh',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  overflow: 'hidden',
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "93%",
  height: "5vh",
  marginTop: 'calc(4vh + 20px)',
  position: "relative",
  padding: theme.spacing(1, 2),
  paddingBottom: 0,
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

const GlowingCircle = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '40vh',
  aspectRatio: '1 / 1',
  borderRadius: '50%',
  background: 'radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%)',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  marginBottom: theme.spacing(1),
  animation: `${float} 3s ease-in-out infinite`,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '10rem',
  padding: theme.spacing(1, 2),
  color: 'white',
  textTransform: 'none',
  margin: theme.spacing(0, 1),
  height: '7vh',
  padding: '12px 16px',
  backgroundColor: '#5529ec',
  backgroundImage: 'radial-gradient(75% 50% at 50% 0%, #e9f0ff00, transparent),radial-gradient(75% 35% at 50% 80%, #ffffff54, transparent)',
  boxShadow: `inset 0 -2px 4px 1px rgba(56, 41, 236, 0.6), inset 0 -4px 4px 1px #c5b3ff, inset 0 0 0px 8px rgba(255, 255, 255, 0.2), 0 1px 4px 1px rgba(56, 41, 236, 0.2), 0 1px 3px 1px #5529ec`,
  color: '#fff',
  textShadow: '0 1px 1px #3829ec',
  textTransform: 'inherit',
  transition: 'border-color 0.2s, transform 0.2s, background-color 0.2s',
  cursor: 'pointer',
  margin: '3px',
  '&:hover': {
    transform: 'scale(1.04)',
  },
  '&:active': {
    borderColor: '#2e23d6',
    transform: 'scale(0.96)',
    backgroundColor: '#2e23d6',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '1px',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '50px',
    width: '80%',
    height: '40%',
    backgroundImage: 'linear-gradient(to bottom, #e9f0ff, transparent)',
    opacity: 0.75,
  },
  '&:focus': {
    outline: 'none',
  }
}));

const TipBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(13, 30, 53, 0.7)',
  // backgroundColor: 'rgb(35 41 51)',
  borderRadius: theme.spacing(4),
  padding: '5px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '46%',
  height: '7vh',
  transition: 'all 0.3s ease',
  zIndex: 2,
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 0 15px rgba(255, 255, 255, 0.3)',
  },
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: theme.spacing(2),
  backgroundColor: 'rgba(224, 224, 224, 0.3)',
  '& .MuiLinearProgress-bar': {
    backgroundImage: 'linear-gradient(to right, #0984ff, #e063df, #5cff62)',
  },
}));

const Particle = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '10px',
  height: '10px',
  background: 'white',
  borderRadius: '50%',
  opacity: 0,
  animation: `${particleAnimation} 2s ease-out infinite`,
}));

const Shadow = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '2vh',
  width: '25vh',
  height: '4vh',
  background: 'rgba(0, 0, 0, 0.75)',
  borderRadius: '50%',
  filter: 'blur(9px)',
  animation: `${shadowAnimation} 3s ease-in-out infinite`,
  zIndex: 1,
}));

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const rotate360 = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const particleAnimation = keyframes`
  0% { transform: translate(0, 0) scale(1); opacity: 1; }
  100% { transform: translate(var(--tx), var(--ty)) scale(0); opacity: 0; }
`;

const shadowAnimation = keyframes`
  0% { transform: scaleX(1); }
  50% { transform: scaleX(1.2); }
  100% { transform: scaleX(1); }
`;

const jumpVibrateRotate = keyframes`
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(10deg);
  }
  10%, 30%, 70%, 90% {
    transform: translateX(-2px) rotate(-5deg);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(2px) rotate(5deg);
  }
`;

export default function Home(props) {
  const { userData, setUserProfileInfo, showSnackbar, telApp, ENDPOINT } = props;
  const [userProfileInfo, setLocalUserProfileInfo] = useState(null);
  const [remainingNextFarming, setRemainingNextFarming] = useState(null);
  const [isActivatingFarmingButton, setIsActivatingFarmingButton] = useState(false);
  const [farmingProgressPercent, setFarmingProgressPercent] = useState(0);
  const [levelProgressPercentage, setLevelProgressPercentage] = useState(0);
  const [openLeaguesPopup, setOpenLeaguesPopup] = useState(false);
  const [openSendTipPopup, setOpenSendTipPopup] = useState(false);
  const [openHistoryPopup, setOpenHistoryPopup] = useState(false);
  const [openRewardsPopup, setOpenRewardsPopup] = useState(false);
  const [openNewLeaguePopup, setOpenNewLeaguePopup] = useState(false);
  const [historyActivityType, setHistoryActivityType] = useState('received');
  const [requiresRewardClaim, setRequiresRewardClaim] = useState(false);
  const [totalInteractionPoints, setTotalInteractionPoints] = useState({ received: 0, sent: 0 });
  const [openConfetti, setOpenConfetti] = useState(false);
  const [particles, setParticles] = useState([]);
  const [farmStartTime, setFarmStartTime] = useState(null);
  const [farmingIntervalMs, setFarmingIntervalMs] = useState(6 * 60 * 60 * 1000); // 6 hours in milliseconds

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const renderParticle = (particle) => {
    const commonStyle = {
      width: `${particle.size}px`,
      height: `${particle.size}px`,
      background: `url(https://img.icons8.com/pulsar-gradient/48/star.png) no-repeat center center`,
      backgroundSize: 'cover',
      opacity: 1,
      filter: `drop-shadow(0 0 13px gold)`,
    };

    switch (particle.shape) {
      case 'square':
        return <div style={commonStyle} />;
      case 'triangle':
        return (
          <div
            style={{
              ...commonStyle,
              width: 0,
              height: 0,
              backgroundColor: 'transparent',
              borderLeft: `${particle.size / 2}px solid transparent`,
              borderRight: `${particle.size / 2}px solid transparent`,
              borderBottom: `${particle.size}px solid ${particle.color}`,
            }}
          />
        );
      default: // circle
        return <div style={{ ...commonStyle, borderRadius: '50%' }} />;
    }
  };

  useEffect(() => {
    // const shapes = ['circle', 'square', 'triangle'];
    const shapes = ['circle'];
    const newParticles = Array.from({ length: 30 }, (_, i) => ({
      id: i,
      size: Math.random() * 5 + 50,
      tx: (Math.random() - 0.5) * 500,
      ty: (Math.random() - 0.5) * 500,
      delay: Math.random(),
      duration: Math.random() * 4 + 5,
      shape: shapes[Math.floor(Math.random() * shapes.length)],
      // color: `hsl(${Math.random() * 360}, 100%, 50%)`,
      color: `#fff`,
    }));
    setParticles(newParticles);
  }, []);

  const fetchUserData = useCallback(async () => {
    if (!userData || !userData.id) return;

    try {
      const response = await userApi.getUserData(userData.id);
      setLocalUserProfileInfo(response.data);
      setUserProfileInfo(response.data);

      if (response.data.isFarmingActive) {
        setFarmStartTime(Date.parse(response.data.farmStartedAt));
      } else {
        setFarmStartTime(null);
      }

      return response.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      showSnackbar("Failed to fetch user data", 'error');
    }
  }, [userData, setUserProfileInfo, telApp]);

  const fetchTotalTips = useCallback(async () => {
    if (!userData || !userData.id) return;

    try {
      const response = await userApi.getTipHistory(userData.id);
      const tipHistory = response.data.tipHistory;

      const totalSent = tipHistory.sent
        ? Object.values(tipHistory.sent).reduce((sum, tip) => sum + tip.amount, 0)
        : 0;

      const totalReceived = tipHistory.received
        ? Object.values(tipHistory.received).reduce((sum, tip) => sum + tip.amount, 0)
        : 0;

      setTotalInteractionPoints({ received: totalReceived, sent: totalSent });
    } catch (error) {
      console.error('Error fetching tips:', error);
    }
  }, [userData]);

  useEffect(() => {
    fetchUserData();
    fetchTotalTips();

    // Set header color
    telApp.setHeaderColor('#22252a');

    const interval = setInterval(() => {
      fetchUserData();
    }, 10000);

    return () => clearInterval(interval);
  }, [fetchUserData, fetchTotalTips]);

  useEffect(() => {
    if (userProfileInfo) {
      setRequiresRewardClaim(userProfileInfo.requiresRewardClaim);
      setOpenNewLeaguePopup(userProfileInfo.achievedNewLeague);

      if (userProfileInfo.points && userProfileInfo.nextLeaguePointsRequired) {
        setLevelProgressPercentage(Math.round((userProfileInfo.points 
          / (userProfileInfo.nextLeaguePointsRequired + userProfileInfo.points)) * 100));
      }
    }
  }, [userProfileInfo]);

  useEffect(() => {
    let intervalId;

    if (farmStartTime) {
      const updateFarmingProgress = () => {
        const now = Date.now();
        const nextFarmingTimeMs = farmStartTime + farmingIntervalMs;
        const remainingTime = calculateRemainingTime_HMS(now, nextFarmingTimeMs);
        const passedTimePercentage = 100 - Math.round(100 * (nextFarmingTimeMs - now) / farmingIntervalMs);
        
        setFarmingProgressPercent(passedTimePercentage);
        setRemainingNextFarming(`${remainingTime.hours}:${remainingTime.minutes}:${remainingTime.seconds}`);

        if (now >= nextFarmingTimeMs) {
          clearInterval(intervalId);
          setFarmStartTime(null);
          fetchUserData(); // Fetch updated user data when farming completes
        }
      };

      updateFarmingProgress(); // Initial update
      intervalId = setInterval(updateFarmingProgress, 1000); // Update every second
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [farmStartTime, farmingIntervalMs, fetchUserData]);

  const handleFarming = async () => {
    if (requiresRewardClaim) {
      showSnackbar('Rewards claimed successfully!', 'success');
      setOpenConfetti(true);
      setFarmingProgressPercent(0);
      setRequiresRewardClaim(false);
      return;
    }

    setIsActivatingFarmingButton(true);

    try {
      const response = await userApi.activateFarm(userData.id)
      .then(() => {
        fetchUserData();
      })
      setOpenConfetti(true);
      showSnackbar("Farming started successfully!", "success");
      
      // Update farm start time
      if (response && response.farmStartedAt) {
        setFarmStartTime(Date.parse(response.farmStartedAt));
      }
    } catch (error) {
      showSnackbar("Failed to start farming", "error");
      console.error(error);
    } finally {
      setTimeout(() => setIsActivatingFarmingButton(false), 1000);
    }
  };

  const handleCheckIn = () => {
    // Implement check-in functionality
    showSnackbar('Check-in feature not implemented yet', 'info');
  };

  const handleTipBoard = (type) => {
    setHistoryActivityType(type);
    setOpenHistoryPopup(true);
  };

  return (
    <StyledPaper>
      <HeaderContainer>
        <Button
          variant="contained"
          size="small"
          sx={{ 
            textTransform: 'none', 
            background: 'linear-gradient(45deg, #4a50ea, #8c6edf)',
            borderRadius: '8px 22px 8px 8px',
            height: '5vh',
            width: '23vw',
            minWidth: 'auto',
            transition: 'all 0.3s ease',
            paddingRight: '15px',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
          onClick={handleCheckIn}
        >
          <img src={CheckInPng} alt="Check-in" width="30px" height="53px" />
          <Typography fontSize={'13px'} fontFamily={'sora, sans-serif'} fontWeight={500} sx={{ lineHeight: '13px' }}>daily<br/>check</Typography>
        </Button>
        <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1} mb={'15vw'} mr={2}>
          <img width="45px" height="45px" src={'https://img.icons8.com/external-icongeek26-outline-gradient-icongeek26/64/external-fox-origami-icongeek26-outline-gradient-icongeek26.png'} alt="Polygon" style={{ marginRight: '2px' }} />
          <Typography variant="h6" fontFamily={'sora, sans-serif'} fontWeight={400}>{userProfileInfo?.firstname}</Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ 
          textTransform: 'none', 
          background: 'linear-gradient(45deg, #4a50ea, #8c6edf)',
          borderRadius: '22px 8px 8px 8px',
          width: '18vw',
          height: '5vh',
          px: 1,
          transition: 'all 0.3s ease',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}>
          <Typography fontSize={'16px'} fontFamily={'sora, sans-serif'} fontWeight={600} sx={{ ml: '15px', mr: 'auto' }}>{formatNumber(userProfileInfo?.diamonds)}</Typography>
          <img src={DiamondPng} alt="diamond" width="85px" height="105px" style={{ position: 'relative', right: '0.9rem', bottom: '0.1rem', transform: 'rotate(45deg)' }} />
        </Box>
      </HeaderContainer>

      <ContentContainer>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={0.5} mb={0.5} onClick={() => setOpenLeaguesPopup(true)}>
          <Typography variant="body2" fontFamily={'sora, sans-serif'}>Points: {formatNumber(userProfileInfo?.points)}</Typography>
          <Typography variant="body2" align="center" fontFamily={'sora, sans-serif'}>
            {`Level ${userProfileInfo?.leagueIndex}/10`}
          </Typography>
        </Box>

        <StyledLinearProgress variant="determinate" value={levelProgressPercentage} sx={{ mb: 1.5 }} />

        <Box display="flex" justifyContent="space-between" mb={1.5}>
          <TipBox onClick={() => handleTipBoard('received')}>
            <Box component="img" src={ReceiveTipIcon} alt="receive-tip-icon" width="37px" height="65px" mr={1} ml={1} />
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="caption" fontFamily={'sora, sans-serif'} fontWeight={600}>Tip's Received</Typography>
              <Typography variant="body1" fontFamily={'sora, sans-serif'} fontWeight={600} mx="auto">{formatNumber(totalInteractionPoints.received)}</Typography>
            </Box>
          </TipBox>
          <TipBox onClick={() => handleTipBoard('sent')}>
            <Box component="img" src={SendTipIcon} alt="send-tip-icon" width="37px" height="37px" mr={0.5} ml={1} />
            <Box display="flex" flexDirection="column" justifyContent="center" ml={2}>
              <Typography variant="caption" fontFamily={'sora, sans-serif'} fontWeight={600}>Tip's Sent</Typography>
              <Typography variant="body1" fontFamily={'sora, sans-serif'} fontWeight={600} mx="auto">{formatNumber(totalInteractionPoints.sent)}</Typography>
            </Box>
          </TipBox>
        </Box>

        <Typography variant="h6" align="center" fontFamily={'sora, poppins'} fontWeight={800} sx={{ zIndex: 9, mb: 0.5 }}>
          Daily Budget
        </Typography>
        <Typography fontSize={isSmallScreen ? "24px" : "32px"} align="center" fontFamily={'sora, poppins'} fontWeight={800} sx={{ 
          zIndex: 9, 
          lineHeight: '1',
          animation: `${pulse} 2s infinite ease-in-out`,
        }}>
          {formatNumber(userProfileInfo?.allowance)} <Typography component="span" variant="h6" fontWeight={600}>/ {formatNumber(userProfileInfo?.initialAllowance)}</Typography>
        </Typography>

        <GlowingCircle>
          <Avatar
            src={FoxiCharacter}
            sx={{ 
              width: '100%', 
              height: '100%', 
              maxWidth: '35vh', 
              maxHeight: '35vh', 
              borderRadius: '0%', 
              zIndex: 9,
            }}
          />
          {particles.map((particle) => (
            <Particle
              key={particle.id}
              style={{
                '--tx': `${particle.tx}px`,
                '--ty': `${particle.ty}px`,
                width: `0px`,
                height: `0px`,
                animationDelay: `${particle.delay}s`,
                animationDuration: `${particle.duration}s`,
              }}
            >
              {renderParticle(particle)}
            </Particle>
          ))}
                 <Box
            sx={{
              width: "100%",
              height: "100%",
              maxWidth: "42vh",
              maxHeight: "42vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              marginBottom: "3vh",  
              // background: `url(${NeonCircle}) no-repeat`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              border: '10px solid #ffffff47',
              borderRadius: '38%',
              transform: "scaleX(-1)",
              filter: "hue-rotate(290deg)",
              zIndex: 1,
              animation: `${rotate360} 15s linear infinite`,
            }}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              maxWidth: "23vh",
              maxHeight: "23vh",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              // boxShadow: "rgb(255 135 27) -2.44vh -2.88vh 6vh 3.78vh, gold -2.44vh 3.12vh 6vh 3.78vh, #ff0034 -8.44vh -5.88vh 9vh 3.78vh, rgb(255, 0, 159) 0vh -2.22vh 5.12vh -5.56vh, rgba(255, 0, 159, 0.9) 0vh 4.22vh 8.88vh 4.44vh",
              boxShadow: "rgb(255 135 27 / 86%) -24vh -12vh 20vh 3.78vh, #4b32ffcf 12vh 15vh 20vh 7vh, #ff0034c4 15vh -16vh 20vh 3.78vh, rgb(0 221 255) 10vh -20vh 20vh 3vh, rgba(255, 0, 159, 0.9) -16vh 11vh 23vh 4.44vh",
              transform: "rotate(250deg)",
              zIndex: 1,
              animation: `${rotate360} 10s linear infinite`,
            }}
          />
          {/* <Box  
            sx={{
              width: "100%",
              height: "100%",
              maxWidth: "21vh",
              maxHeight: "21vh",
              borderRadius: "50%",
              background: "conic-gradient(from 180deg, rgb(102 24 255), rgb(0 150 175), rgb(255 0 104), rgb(102 24 255))",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              padding: "3.34vh",
              mask: "radial-gradient(farthest-side, transparent calc(100% - 3.34vh), white 0)",
              zIndex: 1,
              animation: `${rotate360} 8s linear infinite reverse`,
            }}
          /> */}
          <Shadow />
        </GlowingCircle>

        <Box display="flex" mt="auto" mb={10}>
          {!requiresRewardClaim ?
            <StyledButton variant="contained" 
            onClick={handleFarming}
            sx={{ 
              flex: '2', 
              justifyContent: 'space-evenly', 
              paddingLeft: '0px',
              borderRadius: '10rem' }}>
              <Typography fontSize="0.95rem" fontFamily="sora, sans-serif" fontWeight="bold" display="flex" alignItems="flex-end" pr={2} pl={1}>
                  <img width="25" height="25" src="https://img.icons8.com/3d-fluency/94/tractor.png" alt="tractor" style={{ marginRight: '0.35rem' }}/>
                  {isActivatingFarmingButton ? "Activating" : farmStartTime ? "Farming.." : "Farm"} 
              </Typography>
              {farmStartTime &&
                <Typography fontSize="0.85rem" display="block" fontFamily="sora, sans-serif" fontWeight="bold"
                  sx={{ 
                    color: 'gold',
                    whiteSpace: 'nowrap', 
                    width: '100px', 
                    height: '30px', 
                    lineHeight: '16px',
                    position: 'relative',
                    left: '10px',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: '-3px',
                      left: '-7px',
                      width: '110%',
                      height: '125%',
                      opacity: '0.85',
                      borderLeft: '5px solid #4815ff',
                      borderRadius: '25px',
                      zIndex: '1',
                    }
                  }}> 
                    {remainingNextFarming || "00:00:00"} <br /> {`${formatNumber(farmingProgressPercent * 1.5)} Point`}
                </Typography>
              }
            </StyledButton> 
            : 
            <StyledButton variant="contained" 
              onClick={handleFarming}
              sx={{ 
                flex: '1.65', 
                justifyContent: 'space-evenly', 
                borderRadius: '10rem',
                textAlign: 'center',
                alignItems: 'center',
                px: '5px',
                paddingLeft: '8vw',
                fontSize: '2.4vh',
                fontFamily: 'sora, sans-serif',
                fontWeight: 'bold',
                '::after': { content: '""', width: '80%', height: '35%', left: '53.5%', } }}>
                <Box component="img" src="https://img.icons8.com/external-wanicon-flat-wanicon/64/external-egg-gift-box-wanicon-flat-wanicon.png" alt="external-egg-gift-box-wanicon" 
                    sx={{ position: 'absolute', left: '0px', bottom: '5px', animation: `${jumpVibrateRotate} 1.5s linear infinite`, zIndex: '2', width: '14vw', height: '14vw' }}/>
                Claim Rewards 
            </StyledButton> 
          }
          <StyledButton variant="contained"
            onClick={() => setOpenSendTipPopup(true)}
            sx={{ 
             flex: '1',
             borderRadius: '10rem',
             fontSize: farmStartTime ? '1.95vh' : '2.2vh',
             fontFamily: 'sora, sans-serif', 
             fontWeight: 'bold', 
          }}>
              <img width="25" height="25" src="https://img.icons8.com/3d-fluency/94/nui2--v5.png" alt="nui2--v5" style={{ marginRight: '0.25rem' }} />            
              Send Tip
          </StyledButton>
        </Box>
      </ContentContainer>

      <BottomTabs activeTab={0} />

      <LeaguesPopup 
        userId={userProfileInfo?.userId} 
        formatNumber={formatNumber}
        nextLeaguePointsRequired={userProfileInfo?.nextLeaguePointsRequired}
        points={userProfileInfo?.points}
        userLeagueIndex={userProfileInfo?.leagueIndex} 
        open={openLeaguesPopup} 
        onClose={() => setOpenLeaguesPopup(false)} 
        ENDPOINT={ENDPOINT}/>

      <SendTipPopup 
         userId={userProfileInfo?.userId} 
         remainingAllowance={userProfileInfo?.allowance} 
         open={openSendTipPopup} 
         onClose={() => setOpenSendTipPopup(false)} 
         showSnackbar={showSnackbar}
         telApp={telApp} 
         ENDPOINT={ENDPOINT}/>

      <HistoryPopup 
         userId={userProfileInfo?.userId} 
         open={openHistoryPopup} 
         onClose={() => setOpenHistoryPopup(false)}
         formatNumber={formatNumber} 
         activeTab={historyActivityType}
         setActiveTab={setHistoryActivityType}
         ENDPOINT={ENDPOINT}/>

      <RewardsPopup 
         open={openRewardsPopup} 
         onClose={() => setOpenRewardsPopup(false)}
         showSnackbar={showSnackbar} 
         userProfileInfo={userProfileInfo}
         setUserProfileInfo={setUserProfileInfo}
         setOpenConfetti={setOpenConfetti} 
         telApp={telApp} 
         ENDPOINT={ENDPOINT} />

      <NewLeaguePopup 
        leagueIndex={userProfileInfo?.leagueIndex} 
        league={userProfileInfo?.league} 
        open={openNewLeaguePopup} 
        onClose={() => setOpenNewLeaguePopup(false)} 
        setOpenConfetti={setOpenConfetti} />

      <Confetti openConfetti={openConfetti} />
    </StyledPaper>
  );
}