// src/components/HistoryPopup.jsx
import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, IconButton, Avatar, ToggleButton, ToggleButtonGroup,
  List, ListItem, ListItemText, Divider, CircularProgress
 } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { userApi } from '../../services/api';
import { formatNumber } from '../../utils/formatters';
import PopupBackground from '../../assets/images/bg.png';

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100vw',
  height: '93vh',
  background: 'linear-gradient(358deg, black, #4b00a6bd)',
  backdropFilter: 'blur(5px)',
  // background: `url(${PopupBackground}) repeat-x`,
  // backgroundSize: 'cover',
  borderTopLeftRadius: theme.spacing(7),
  borderTopRightRadius: theme.spacing(7),
  borderTop: '5px solid orange',
  boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: 'white',
  zIndex: 999,
}));

const StyledList = styled(List)(({ theme }) => ({
  '& .MuiListItem-root': {
    padding: '4px 0px',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: '90vw',
  height: '60px',
  borderRadius: '15px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  margin: '8px 0px',
}));

const HistoryPopup = ({ userId, open, onClose, telApp }) => {
  const [activeTab, setActiveTab] = useState('received');
  const [historyActivity, setHistoryActivity] = useState(null);
  const [totalInteractionPoints, setTotalInteractionPoints] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchHistoryActivity = async () => {
      if (!userId || !open) return;

      setLoading(true);
      try {
        const response = await userApi.getTipHistory(userId);
        const tipHistory = response.data.tipHistory;
        const fieldObj = activeTab === 'received' ? 'from' : 'to';
        
        const mappedResponse = Object.entries(tipHistory[activeTab] || {}).map(([key, value]) => ({ 
          userId: value[fieldObj].userId,
          username: value[fieldObj].username,
          firstname: value[fieldObj].firstname, 
          amount: value.amount 
        })).reverse();

        setHistoryActivity(mappedResponse);

        // Calculate total points
        const totalPoints = mappedResponse.reduce((total, activity) => total + Number(parseFloat(activity.amount).toFixed(0)), 0);
        setTotalInteractionPoints(totalPoints);
      } catch (error) {
        console.error('Error fetching tip history:', error);
        telApp.showAlert('Failed to fetch tip history. Please try again.');
        setHistoryActivity(null);
      } finally {
        setLoading(false);
      }
    };

    fetchHistoryActivity();
  }, [userId, activeTab, open, telApp]);

  const handleTabChange = (event, newValue) => {
    if (newValue !== null) {
      setActiveTab(newValue);
    }
  };

  return (
    <PopupContainer sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)' }}>
      <Box display="flex" justifyContent="flex-end" p={2} position="absolute" right='3vw' top='1vh'>
        <IconButton onClick={onClose}>
          <CloseIcon fontSize="large" htmlColor="white" />
        </IconButton>
      </Box>
      
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="h5" fontFamily="Poppins-Bold" fontSize="20px" sx={{ width: 'max-content', position: 'relative', left: '6vw', top: '5vh' }}>
          History
        </Typography>
        <Typography variant="body1" fontFamily="sora, sans-serif" sx={{ alignSelf: "center", mt: 8 }}>
          Total {activeTab === 'received' ? 'Points Received' : 'Amount You Tipped'}
        </Typography>
        <Typography variant="h4" fontFamily="Poppins-Bold" sx={{ alignSelf: "center", mb: 2 }}>
          {formatNumber(totalInteractionPoints)}
        </Typography>
      </Box>

      <ToggleButtonGroup
        value={activeTab}
        exclusive
        onChange={handleTabChange}
        aria-label="tip history type"
        sx={{ 
          mt: 2, 
          ml: 6, 
          justifyContent: 'center', 
          backgroundColor: 'rgba(255, 255, 255, 0.2)', 
          borderRadius: '20px',
          '& .Mui-selected': { backgroundColor: 'rgba(0, 0, 0, 0.2)', color: 'white' },
        }}
      >
        <ToggleButton value="received" aria-label="received tips" sx={{ width: '35vw', fontFamily: 'Poppins-Medium, sans-serif', fontWeight: 'bold', borderRadius: '20px', color: 'white' }}>
          Received
        </ToggleButton>
        <ToggleButton value="sent" aria-label="sent tips" sx={{ width: '35vw', fontFamily: 'Poppins-Medium, sans-serif', fontWeight: 'bold', borderRadius: '20px', color: 'white' }}>
          Sent
        </ToggleButton>
      </ToggleButtonGroup>

      <Box display="flex" justifyContent="center" alignItems="center" width="100%">
        <StyledList className="custom-scrollbar" dense sx={{ maxHeight: '450px', overflow: 'auto', '&::-webkit-scrollbar': { width: '5px', height: '5px' }, '&::-webkit-scrollbar-track': { background: 'transparent' }, '&::-webkit-scrollbar-thumb': { background: 'rgba(255, 255, 255, 0.2)', borderRadius: '5px' }, '& .MuiListItem-root:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' }, '& .MuiListItemIcon-root': { minWidth: '45px' }}}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="450px">
              <CircularProgress />
            </Box>
          ) : historyActivity && historyActivity.length > 0 ? (
            historyActivity.map((activity) => (
              <StyledListItem key={activity.userId}>
                <Avatar
                  alt={activity.firstname}
                  src={`${process.env.REACT_APP_API_URL}/public/users/avatars/${activity.userId}.jpg`}
                  sx={{ width: 45, height: 45, ml: 1 }}
                  loading="lazy"
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                  <ListItemText 
                    primary={activity.username} 
                    secondary={activity.firstname}
                    primaryTypographyProps={{ fontFamily: 'Sora', fontWeight: 'bold', fontSize: '18px' }}
                    secondaryTypographyProps={{ fontFamily: 'Sora', fontWeight: '300', fontSize: '15px', color: 'white' }}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
                  <ListItemText 
                    primary={`${activeTab === 'sent' ? '-' : '+'}${formatNumber(activity.amount)}`} 
                    sx={{ position: 'absolute', right: '15px', top: '15px', '& .MuiTypography-root': { color: 'white', fontFamily: 'Sora', fontWeight: '300', fontSize: '15px' }}}
                  />
                </Box>
              </StyledListItem>
            ))
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" height="450px">
              <Typography variant="h6" fontFamily="Poppins-Bold">No activity yet</Typography>
            </Box>
          )}
        </StyledList>
      </Box>
    </PopupContainer>
  );
};

export default HistoryPopup;