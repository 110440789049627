// src/components/Home/ConfirmTaskPopup.jsx
import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { styled } from '@mui/system';
import { taskApi } from '../../services/api';

const PopupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '96%',
  height: '30vh',
  background: 'linear-gradient(184deg, #698dff, transparent)',
  backdropFilter: 'blur(10px)',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '25px',
  boxShadow: theme.shadows[5],
  transform: 'translateY(100%)',
  transition: 'transform 0.5s ease-in-out',
  marginLeft: 'auto',
  marginRight: 'auto',
  '*': {
    color: 'white',
  },
  zIndex: 9999,
}));

const StyledTabButton = styled(Button)(({ theme }) => ({
  color: 'white',
  fontSize: '18px', 
  width: '75vw', 
  height: '8vh', 
  borderRadius: '20px', 
  fontFamily: 'Sora, sans-serif', 
  fontWeight: 700, 
  textTransform: 'Capitalize',
  marginTop: '0.5vh',
  backgroundColor: 'royalblue',
  '&:hover': {
    backgroundColor: 'royalblue',
  },
}));

function ConfirmTaskPopup({ 
  task, userId, telApp, open, 
  onClose, setOpenConfetti,
  setStopFetchingTasks 
}) {
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [isTaskCompleted, setIsTaskCompleted] = useState(false);
  const [timerDuration, setTimerDuration] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const determineWaitTime = useCallback((taskTitle) => {
    const lowercaseTitle = taskTitle.toLowerCase();
    if (lowercaseTitle.includes('subscribe') || lowercaseTitle.includes('follow')) {
      return 10;  // 10 seconds for subscribe tasks
    } else if (lowercaseTitle.includes('watch')) {
      return 40;  // 40 seconds for watch tasks
    } else if (lowercaseTitle.includes('repost')) {
      return 12;  // 12 seconds for repost tasks
    }
    return 0;  // No wait time for other tasks (like Telegram)
  }, []);

  const handleSubButton = useCallback(() => {
    if (!task) return;

    window.open(task.link, '_blank');
    const waitTime = determineWaitTime(task.title);

    if (waitTime > 0) {
      setTimerDuration(waitTime);
      setIsTimerActive(true);

      const interval = setInterval(() => {
        setTimerDuration((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setIsTimerActive(false);
            setIsTaskCompleted(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      setIntervalId(interval);
    } else {
      setIsTaskCompleted(true);
    }
  }, [task, determineWaitTime]);

  const handleCheckButton = useCallback(async () => {
    if (!isTaskCompleted || !task) return;

    setStopFetchingTasks(true);

    try {
      await taskApi.checkTask(task.taskId, userId);
      setOpenConfetti(true);
      onClose();
    } catch (error) {
      telApp.showAlert('Failed to check task status! For support, please check bot profile bio.');
      console.error('Error checking task:', error);
    } finally {
      setStopFetchingTasks(false);
      setTimeout(() => {
        setOpenConfetti(false);
      }, 1000);
    }
  }, [isTaskCompleted, task, userId, setStopFetchingTasks, setOpenConfetti, onClose, telApp]);

  useEffect(() => {
    if (!open && intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [open, intervalId]);

  useEffect(() => {
    if (!open) {
      setIsTimerActive(false);
      setIsTaskCompleted(false);
      setTimerDuration(0);
    }
  }, [open]);

  return (
    <PopupContainer sx={{ transform: open ? 'translateY(0)' : 'translateY(107%)' }}>
      <Box 
        display={open ? 'block' : 'none'} 
        width="200vw" 
        height="210vh" 
        position="absolute" 
        top={0} 
        left={0} 
        right={0} 
        bottom={0} 
        bgcolor="black" 
        sx={{ opacity: '0.5', backdropFilter: 'blur(10px)', transform: 'translate(-50%, -50%)' }} 
      />

      <Box display="flex" justifyContent="flex-end" p={2} position="absolute" right={0}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="h5" position="relative" top="25px" fontFamily="Sora" fontSize="20px" ml={2} sx={{ width: 'max-content' }}>
          {task ? (task.title.length > 23 ? `${task.title.slice(0, 23)}...` : task.title) : ''}
        </Typography>
      </Box>
      
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%" position="relative" top="6vh">
        <StyledTabButton onClick={handleSubButton} disabled={isTimerActive}>
          {isTimerActive ? `Please wait ${timerDuration} seconds` : 'Start Task'}
        </StyledTabButton>
        <StyledTabButton onClick={handleCheckButton} disabled={!isTaskCompleted}>
          Check Task
        </StyledTabButton>
      </Box>
    </PopupContainer>
  );
}

export default ConfirmTaskPopup;