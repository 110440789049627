import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Typography, Grid, List, ListItem, ListItemText, CssBaseline } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReferralBackground from '../assets/images/referral-bg.png';

// Import referral components
import FriendsList from './Referral/FriendsList';
import ShareLink from './Referral/ShareLink';
import ButtonTabs from './Tabs/Tabs';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#8e44ad',
    },
    background: {
      default: '#1e0533',
      paper: '#3c1f5c',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
});

export default function InviteFriends(props) {
  const { userData, userProfileInfo, telApp, ENDPOINT } = props;
  const [userId, setUserId] = useState(null);
  const [friends, setFriends] = useState([]); // Default to empty array to avoid undefined issues
  const [friendsCount, setFriendsCount] = useState(0);
  const [isFetchingFriends, setIsFetchingFriends] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  const [shareMessageLink, setShareMessageLink] = useState('');

  useEffect(() => {
    setInviteLink(createInviteLink(userId));

    const message = `Join Wonka Tips now! 🎉
Get daily allowance and enjoy your free tipping.\n\nClick the link below to get started.\n${createInviteLink(userId)}`;
    setShareMessageLink(createShareMessageLink(message));
  }, [userId]);

  useEffect(() => {
    if (userProfileInfo) {
         const friendsCount = typeof (userProfileInfo.referralsList) === "string" ? 0 : (userProfileInfo.referralsList).length;
         setFriendsCount(friendsCount);
         
         const friends =  typeof (userProfileInfo.referralsList) === "string" ? [] : (userProfileInfo.referralsList).slice(0, 25);
         setFriends(friends);
    } else {
      setIsFetchingFriends(true)
    }
  }, [userProfileInfo, friends]);

  useEffect(() => {
    if (userData) {
      setUserId(userData.id);
    }
  }, [userData]);

  const createInviteLink = (userId) => {
    return `https://t.me/WonkaTipsBot/launch?startapp=${userId}`;
  };

  const createShareMessageLink = (text) => {
    const encodedText = encodeURIComponent(text);
    return `https://t.me/share/url?url=${encodedText}`;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteLink);
    telApp.showAlert('Invite link copied!');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100vw', height: '110vh', minHeight: '100vh', display: 'flex', backgroundImage: `url(${ReferralBackground})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          sx={{
            height: '100vh',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignContent: 'space-between',
            justifyContent: 'space-around',
            alignItems: 'center',
            pt: '40vh',
          }}
        >
          <Typography variant="h4" component="h1" fontFamily={'Poppins-Bold, sans-serif'} fontWeight="bold" align="center" sx={{ color: 'white' }}>
            Invite Friends!
          </Typography>
          <Typography variant="subtitle1" component="h2" fontFamily={'Sora, sans-serif'} align="center" sx={{ color: 'white', mb: 2 }}>
            Get 5% of your friends points
          </Typography>

            {/* Friend list */}
          <FriendsList friends={friends} friendsCount={friendsCount} isFetchingFriends={isFetchingFriends} userId={userId} telApp={telApp} ENDPOINT={ENDPOINT} />

            {/* Share link */}
            <ShareLink inviteLink={inviteLink} shareMessageLink={shareMessageLink} copyToClipboard={copyToClipboard} telApp={telApp} />

            {/* Bottom footer */}
            <ButtonTabs activeTab={3} blurEffect={true} />
        </Grid>
     </Box>
    </ThemeProvider>
  );
}