// src/components/Task.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import { Box, Typography, Button, ToggleButtonGroup, ToggleButton, Container, IconButton } from '@mui/material';
import { taskApi } from '../services/api';
import BottomTabs from './Tabs/Tabs';
import TaskList from './Task/TaskList';
import ConfirmPopup from './Task/ConfirmPopup';
import Confetti from './Confetti/Confetti';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#9c27b0',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#1a0933',
      paper: '#2a1052',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
        },
      },
    },
  },
});

// Star animation
const twinkle = keyframes`
  0% { opacity: 1; transform: scale(1); drop-shadow(0 0 10px gold); }
  50% { opacity: 0.5; transform: scale(0.9); drop-shadow(0 0 15px gold); }
  100% { opacity: 1; transform: scale(1); drop-shadow(0 0 10px gold); }
`;

// Moving from top to bottom animation
const navButtonAnimation = keyframes`
  0% { scale: 0.01; }
  100% { scale: 1; }
`;

// rotate animation
const rotate = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
`;

const Task = ({ userData, telApp, ENDPOINT }) => {
  const [taskType, setTaskType] = useState('Basic');
  const [tasks, setTasks] = useState(null);
  const [activeTask, setActiveTask] = useState(null);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [openConfetti, setOpenConfetti] = useState(false);
  const [stopFetchingTasks, setStopFetchingTasks] = useState(false);

  const fetchTasks = useCallback(async () => {
    if (stopFetchingTasks) return;

    try {
      const response = await taskApi.getAllTasks();
      const mappedTasks = response.data.tasks.map(task => ({
        ...task,
        type: task.type || 'Unknown'
      }));

      // Sort tasks by type: Telegram, X, Youtube, Farcaster
      mappedTasks.sort((a, b) => {
        const typeOrder = { Telegram: 1, X: 2, Youtube: 3, Farcaster: 4 };
        return (typeOrder[a.type] || 5) - (typeOrder[b.type] || 5);
      });

      setTasks(mappedTasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      telApp.showAlert('Failed to fetch tasks. Please try again later.');
    }
  }, [stopFetchingTasks, telApp]);

  useEffect(() => {
    fetchTasks();

   // Set Header Color
   telApp.setHeaderColor('#010510'); 

    const interval = setInterval(fetchTasks, 2000);
    return () => clearInterval(interval);
  }, [fetchTasks]);

  const handleTaskTypeChange = (event, newTaskType) => {
    if (newTaskType !== null) {
      setTaskType(newTaskType);
    }
  };

  const handleTaskClick = (taskId) => {
    setActiveTask(taskId);
    setOpenConfirmPopup(true);
  };

  const handleTaskCompletion = async () => {
    if (!activeTask || !userData) return;

    try {
      setStopFetchingTasks(true);
      const response = await taskApi.checkTask(userData.id, activeTask);
      if (response.data.completed) {
        setOpenConfetti(true);
        telApp.showAlert('Task completed successfully!', 'success');
        fetchTasks(); // Refresh tasks after completion
      }
    } catch (error) {
      console.error('Error completing task:', error);
      telApp.showAlert('Failed to complete task. Please try again.');
    } finally {
      setStopFetchingTasks(false);
      setOpenConfirmPopup(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm" sx={{ minHeight: '100vh', bgcolor: 'transparent', display: 'flex', flexDirection: 'column', pr: 0, pl: '10px', pt: '2vh', overflow: 'hidden' }}>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', py: 4, zIndex: 1 }}>
          <Typography variant="h4" component="h1" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" sx={{ color: 'white', mb: 1, textAlign: 'center' }}>
            E A R N
          </Typography>
          <Typography variant="body1" fontFamily="sora, sans-serif" fontWeight="bold" sx={{ color: '#f48fb1', mb: 3, textAlign: 'center' }}>
            Complete Tasks and earn bonuses
          </Typography>

        {/* Stars */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
            pointerEvents: 'none',
          }}
        >
          {[...Array(5)].map((_, i) => (
            <Box
              key={i}
              component="img"
              src="https://img.icons8.com/pulsar-gradient/50/star.png"
              alt="Star"
              sx={{
                position: 'absolute',
                width: '40px',
                height: '40px',
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                rotate: `${Math.random() * 360}deg`,
                opacity: 0.15,
                transition: 'all 1s ease-in-out',
                filter: 'drop-shadow(0 0 13px gold) drop-shadow(0 0 5px gold)',
              }}
            />
          ))}

          {/* Static Stars */}
          <Box
            component="img"
            src="https://img.icons8.com/pulsar-gradient/50/star.png"
            alt="Star"
            sx={{
              position: 'absolute',
              width: '40px',
              height: '40px',
              top: `9.5vh`,
              right: `22vw`,
              animation: `${twinkle} 2s infinite`,
              filter: 'drop-shadow(0 0 13px gold) drop-shadow(0 0 5px gold)',
            }}
          />
          <Box
            component="img"
            src="https://img.icons8.com/pulsar-gradient/50/star.png"
            alt="Star"
            sx={{
              position: 'absolute',
              width: '55px',
              height: '55px',
              top: `3.5vh`,
              right: `12vw`,
              filter: 'drop-shadow(0 0 13px gold)',
              animation: `${twinkle} 3.5s infinite`,
            }}
          />
          <Box
            component="img"
            src="https://img.icons8.com/pulsar-gradient/50/star.png"
            alt="Star"
            sx={{
              position: 'absolute',
              width: '60px',
              height: '60px',
              top: `9.5vh`,
              right: `4vw`,
              filter: 'drop-shadow(0 0 13px gold)',
              animation: `${twinkle} 4.5s infinite`,
            }}
          />
        </Box>

        {/* Gradient box */}
        <Box
          sx={{
            position: 'absolute',
            top: '60vh',
            right: '10vw',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'transparent',
            width: '20px',
            height: '20px',
            overflow: 'hidden',
            pointerEvents: 'none',
            display: tasks? 'block' : 'none',
            animation: tasks? `${navButtonAnimation} 5s ease-in-out, ${rotate} 20s infinite` : 'none',
            transition: 'all 2s ease-in-out',
            boxShadow: 'rgb(255 135 27 / 93%) -20vh -13vh 20vh 13vh, #4b32ffcf -15vh 20vh 20vh 15vh, #ff0034c4 15vh -16vh 20vh 14vh, rgb(0 221 255) 12vh -8vh 17vh 19vh, rgba(255, 0, 159, 0.9) -16vh 11vh 23vh 4.44vh',
            opacity: 0.9,
            zIndex: 0,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            },
          }} />

          <ToggleButtonGroup
            value={taskType}
            exclusive
            onChange={handleTaskTypeChange}
            aria-label="task type"
            sx={{ mb: 3, justifyContent: 'center', '& .Mui-selected': { backgroundColor: 'rgba(255, 255, 255, 0.5)' } }}
          >
            <ToggleButton value="Basic" aria-label="basic tasks" sx={{ width: '35vw', fontFamily: 'Poppins-Medium, sans-serif', fontWeight: 'bold', borderRadius: '20px'}}>
              Basic
            </ToggleButton>
            <ToggleButton value="Daily" aria-label="daily tasks" sx={{ width: '35vw', fontFamily: 'Poppins-Medium, sans-serif', fontWeight: 'bold', borderRadius: '20px' }}>
              Daily
            </ToggleButton>
          </ToggleButtonGroup>

          <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
            <TaskList 
              tasks={tasks}
              taskType={taskType.toLowerCase()}
              userId={userData?.id}
              telApp={telApp}
              onTaskClick={handleTaskClick}
            />
          </Box>
        </Box>

        <ConfirmPopup 
          task={tasks ? tasks.find(t => t.taskId === activeTask) : null}
          userId={userData?.id}
          telApp={telApp}
          open={openConfirmPopup}
          onClose={() => setOpenConfirmPopup(false)}
          onConfirm={handleTaskCompletion}
          ENDPOINT={ENDPOINT}
        />

        <Confetti openConfetti={openConfetti} x={0.5} y={0.85} />

        <Box sx={{ position: 'sticky', bottom: 0, left: 0, right: 0, zIndex: 10 }}>
          <BottomTabs activeTab={1} blurEffect={true} />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Task;