import React from 'react';
import { Box, Grid, Typography, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import FoxiLoadingBG from '../../assets/images/loading-bg.png';
import LoadingRoadmap from './LoadingRoadmap';

// Inspired by the former CircularProgress.js
function GradientCircularProgress() {
    return (
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#842170" />
              <stop offset="100%" stopColor="#ed745d" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          variant="indeterminate"
          disableShrink
          size={25}
          thickness={7}
          sx={{ 'svg circle': { stroke: 'url(#my_gradient)' }, animationDuration: '1900ms', pl: '10px', verticalAlign: 'middle' }} />
      </React.Fragment>
    );
}

function LoadingScreen(props) {
    const { whitelistGradient = false } = props;

    return (
        <Grid item xs={12} width="105vw" height="105vh" position="absolute" zIndex={9999} sx={{ 
          background: whitelistGradient ? 
            'linear-gradient(356deg, #0e002f, #0076e0a3)' : 
            `url(${FoxiLoadingBG})`,
          backgroundSize: 'cover',
          backgroundColor: 'black',
          backdropFilter: 'blur(10px) drop-shadow(2px 4px 6px black)'}}>
            {/* <Typography variant="h4" fontSize="7vw" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" color="#BEB7FF" position="relative" top="10vh" left="20vw">
               Wonka Roadmap
            </Typography> */}
             <LoadingRoadmap />
             <Typography variant="body2" fontSize="5vw" fontFamily="Poppins-Bold, sans-serif" fontWeight="bold" color="white" position="absolute" bottom="8vh" left="36vw">
               Loading...
             <CircularProgress size={20} thickness={12} sx={{ color: 'white', ml: 1, verticalAlign: 'middle' }} />
            </Typography>

            <link rel="preload" href={FoxiLoadingBG} as="image"></link>
          </Grid>
    );
};

export default LoadingScreen